import { makeStyles, Typography } from "@material-ui/core";
import { ReactElement } from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  element: {
    width: "33.33%",
    height: "400px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    transition: "0.3s",
    // Hover lightens the background color
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
    cursor: "pointer",
    "& h4": {
      fontSize: "2rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.5rem",
      },
    },
  },
}));

export default function ShowcaseBanner(): ReactElement {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <div
        className={classes.element}
        style={{
          backgroundImage:
            "url('https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Bell+Plantation/Image+(004).jpeg')",
        }}
      >
        <div
          className={classes.textContainer}
          onClick={() => history.push("/commericalBuildings")}
        >
          <Typography variant="h4" component="h4">
            Commercial Buildings
          </Typography>
        </div>
      </div>
      <div
        className={classes.element}
        style={{
          backgroundImage:
            "url('https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/Keir/Image+(011).jpeg')",
        }}
        onClick={() => history.push("/agriculturalBuildings")}
      >
        <div className={classes.textContainer}>
          <Typography variant="h4" component="h4">
            Agricultural Buildings
          </Typography>
        </div>
      </div>
      <div
        className={classes.element}
        style={{
          backgroundImage:
            "url('https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/kings-hill/IMG_1356.jpg')",
        }}
        onClick={() => history.push("/roofing-cladding")}
      >
        <div className={classes.textContainer}>
          <Typography variant="h4" component="h4">
            Roofing & Cladding
          </Typography>
        </div>
      </div>
    </div>
  );
}
