import { makeStyles, Typography } from "@material-ui/core";
import { ReactElement } from "react";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "white",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
    padding: "1rem 0",
    [`& img`]: {
      width: 150,
      margin: "1rem",
    },
  },
  title: {
    paddingTop: "3rem",
    paddingLeft: "4rem",
  },
}));
export default function ClientLogos(): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h5" gutterBottom>
        We partner with the following companies:
      </Typography>
      <div className={classes.logoContainer}>
        <img
          src="https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/brett-martin.png"
          alt="Brett Martin"
        />
        <img
          src="https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/kingspan.png"
          alt="Kingspan"
        />
        <img
          src="https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/joriside.png"
          alt="Joriside"
        />
        <img
          src="https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/euroclad.png"
          alt="Euroclad"
        />
        <img
          src="https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/tata.png"
          alt="Tata"
        />
        <img
          src="https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/etert.png"
          alt="Eternit"
        />
      </div>
    </div>
  );
}
