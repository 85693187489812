import { Container, makeStyles, Typography } from "@material-ui/core";
import { Facebook, Instagram, LinkedIn } from "@material-ui/icons";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { navLinks, contacts } from "../../config";
import upgetgo from "./upgetgo-logo.png";
import logo from "./logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.dark,
    padding: "5rem 0",
    [`& a`]: {
      textDecoration: "none",
    },
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  contactMenu: {
    [`& a`]: {
      display: "block",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  contactEmail: {
    marginBottom: "1rem",
  },
  logo: {
    width: 150,
  },
  socialContainer: {
    margin: "2rem 0",
    [`& svg`]: {
      cursor: "pointer",
      [`&:hover`]: {
        color: theme.palette.primary.dark,
      },
    },
  },
  footerMenu: {
    display: "flex",
    justifyContent: "space-between",
    minWidth: 500,
    [`& p`]: {
      margin: "0.5rem 0",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  subMenuItem: {
    color: theme.palette.primary.dark,
  },
  upgetgo: {
    display: "flex",
    alignItems: "center",
    color: "#eee",
    marginTop: 10,
    [`& img`]: {
      marginLeft: 10,
      width: 30,
    },
    [`&:hover`]: {
      color: "lightgrey",
    },
  },
}));

export default function Footer(): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <div className={classes.contactMenu}>
          <Typography color="primary" variant="subtitle1">
            FGA Group Limited
          </Typography>
          {contacts.map((contact) => (
            <>
              <a key={contact.tel} href={contact.tel}>
                <Typography
                  className={classes.subMenuItem}
                  color="primary"
                  variant="caption"
                >
                  {contact.name} {contact.text}
                </Typography>
              </a>
              <a
                key={contact.email}
                href={contact.email}
                className={classes.contactEmail}
              >
                <Typography
                  className={classes.subMenuItem}
                  color="primary"
                  variant="caption"
                >
                  {contact.emailText}
                </Typography>
              </a>
            </>
          ))}
          <Typography color="primary" variant="caption">
            <strong>Head Office</strong> <br /> Home Farm, Sywell, Northampton,
            NN6 0BQ
          </Typography>
        </div>
        <div className={classes.footerMenu}>
          {navLinks.map(({ name, link, subMenuItems }) => {
            if (subMenuItems && subMenuItems.length > 0) {
              return (
                <div>
                  <Typography component="p" color="primary" variant="caption">
                    {name}
                  </Typography>
                  {subMenuItems.map((item) => (
                    <Link key={`footer-${item.name}`} to={item.link}>
                      <Typography
                        component="p"
                        className={classes.subMenuItem}
                        variant="caption"
                      >
                        {item.name}
                      </Typography>
                    </Link>
                  ))}
                </div>
              );
            }

            return (
              <Link key={`footer-${name}`} to={link}>
                <Typography component="p" color="primary" variant="caption">
                  {name}
                </Typography>
              </Link>
            );
          })}
        </div>
        <div>
          <img className={classes.logo} src={logo} alt="FGA Group" />
          <div className={classes.socialContainer}>
            <Facebook
              onClick={() =>
                window.open("https://www.facebook.com/FGAGroupLtd", "_blank")
              }
              color="primary"
            />
            <Instagram
              onClick={() =>
                window.open("https://www.instagram.com/fgagroupltd/", "_blank")
              }
              color="primary"
            />
            <LinkedIn
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/company/fga-group-ltd/",
                  "_blank"
                )
              }
              color="primary"
            />
          </div>
          <Typography color="primary" variant="caption">
            © FGA Group Limited
          </Typography>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://upgetgo.com"
            className={classes.upgetgo}
          >
            <Typography variant="subtitle2" color="inherit">
              Website Designed by UpGetGo
            </Typography>
            <img src={upgetgo} alt="UpGetGo Web design" />
          </a>
        </div>
      </Container>
    </div>
  );
}
