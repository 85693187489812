import { makeStyles } from "@material-ui/core";
import { ReactElement } from "react";

interface Props {
  backgroundImage: string;
  wideStyle?: boolean;
  backgroundPosition?: string;
}
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "50vh",
    paddingBottom: "1rem",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: theme.palette.secondary.dark,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      // minHeight: "14vh",
      alignItems: "bottom",
    },
  },
  rootWide: {
    backgroundSize: "100%",
    backgroundPosition: "100% 45%",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "cover",
      backgroundPosition: "left",
    },
  },
}));

export default function TopPhotoBanner({
  backgroundImage,
  wideStyle,
  backgroundPosition,
}: Props): ReactElement<Props> {
  const classes = useStyles();

  return (
    <div
      style={{
        backgroundImage: `url("${backgroundImage}")`,
        backgroundPosition,
      }}
      className={
        wideStyle ? `${classes.root} ${classes.rootWide}` : classes.root
      }
    ></div>
  );
}
