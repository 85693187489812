import { Container, makeStyles, Typography } from "@material-ui/core";
import GoogleMapReact from "google-map-react";
import PinPoint from "./pinpoint";
import { ReactElement } from "react";
import TopTitle from "../../components/TopTitle";
import { contacts } from "../../config";
import ContactForm from "./form";
import "./pinpoint.css";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5rem 0",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  textContainer: {
    maxWidth: 500,
    [`& h6`]: {
      fontWeight: 500,
      marginBottom: "2rem",
    },
    [`& a`]: {
      textDecoration: "none",
      color: "inherit",
      [`&:hover p`]: {
        color: "#999",
      },
    },
    [`& h5`]: {
      textDecoration: "none",
      color: "inherit",
      [`&:hover h5`]: {
        color: "#999",
      },
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  contactEmail: {
    marginBottom: "1rem",
  },
  formContainer: {
    width: 650,
    [theme.breakpoints.down("md")]: {
      marginTop: "1rem",
    },
  },
  whereContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "2rem",
  },
  mapContainer: {
    width: "60%",
    height: 300,
  },
}));

export default function ContactPage(): ReactElement {
  const classes = useStyles();
  const defaultProps = {
    center: {
      lng: -0.7949129812628183,
      lat: 52.29646086769308,
    },
    zoom: 13,
  };

  return (
    <>
      <TopTitle title="Contact Us" />
      <Container className={classes.container} maxWidth="lg">
        <div className={classes.textContainer}>
          <Typography component="h6" variant="h6">
            We would love to hear from you. Please give us a ring to arrange a
            visit to our office, view one of our live sites or finished
            projects, or to obtain references from our Clients. Alternatively,
            we would be delighted to come and see you to tell you about our
            business.
          </Typography>
          {contacts.map((contact) => (
            <>
              <a key={contact.tel} href={contact.tel}>
                <Typography
                  component="p"
                  color="textPrimary"
                  variant="subtitle1"
                >
                  {contact.name} {contact.text}
                </Typography>
              </a>
              <a key={contact.email} href={contact.email}>
                <Typography
                  component="h5"
                  color="textPrimary"
                  variant="subtitle1"
                  className={classes.contactEmail}
                >
                  {contact.emailText}
                </Typography>
              </a>
            </>
          ))}
          <div className={classes.whereContainer}>
            <Typography component="h5" color="textPrimary" variant="subtitle1">
              <strong>Head Office</strong> <br /> Home Farm, <br /> Sywell,{" "}
              <br /> Northampton, <br /> NN6 0BQ
            </Typography>
            <div className={classes.mapContainer}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyDpCWrenjSCUlyFsflso32HK_19bP7j16E",
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              >
                <PinPoint
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  text="test"
                  lat={defaultProps.center.lat}
                  lng={defaultProps.center.lng}
                />
              </GoogleMapReact>
            </div>
          </div>
        </div>
        <div className={classes.formContainer}>
          <ContactForm />
        </div>
      </Container>
    </>
  );
}
