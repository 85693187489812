import TopBanner from "../../components/TopPhotoBanner";
import { ReactElement } from "react";
import { Container, makeStyles, Typography } from "@material-ui/core";
import MultiGalleryCarousel from "../../components/MultiGalleryCarousel";
import { BuildingImages } from "../../types";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "5rem 0",
    [theme.breakpoints.down("md")]: {
      padding: "1rem 2rem",
    },
  },
  title: {
    margin: "2rem 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  subTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
}));

const buildingImages: BuildingImages[] = [
  {
    title: "HATO",
    subtitle:
      "Install of new 100mm Kingspan composite panels, associated flashings, purlins & new aluminium gutters & downpipes",
    imageUrls: [
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/hato/Image+1.jpeg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/hato/e733349e-c33d-440c-88be-22333a7c2909.JPG",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/hato/Image+(00A).jpeg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/hato/Image+(00C).jpeg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/hato/Image+2.jpeg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/hato/Image+3.jpeg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/hato/Image+4.jpeg",
    ],
  },
  {
    title: "Kings Hill School",
    subtitle:
      "6,000m2 roof strip and install of Euroclad Standing Seam Built Up System, Gutters & Downpipes",
    imageUrls: [
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/kings-hill/IMG_1356.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/kings-hill/IMG_1458.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/kings-hill/IMG_1461.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/kings-hill/IMG_1465.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/kings-hill/IMG_1467.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/kings-hill/IMG-20241105-WA0010.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/kings-hill/IMG-20241105-WA0011.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/kings-hill/IMG-20241105-WA0012.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/kings-hill/IMG-20241105-WA0013.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/kings-hill/IMG-20241105-WA0014.jpg",
    ],
  },
  {
    title: "Caldecote Farm",
    subtitle:
      "8,000m2 Composite Roofing & Cladding install inc Gutters & Downpipes",
    imageUrls: [
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/caldecote-farm/WhatsApp+Image+2024-10-15+at+19.28.19_0634e029.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/caldecote-farm/WhatsApp Image 2024-10-15 at 19.29.58_a2a52f5a.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/caldecote-farm/WhatsApp Image 2024-11-05 at 19.40.45_8d6ee577.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/caldecote-farm/WhatsApp Image 2024-11-05 at 19.40.45_a70f26f6.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/caldecote-farm/WhatsApp Image 2024-11-05 at 19.40.45_d2cbcfae.jpg",
    ],
  },
  {
    title: "RSME Wainscott",
    subtitle:
      "Replacement of existing glazing system with Brett Martin polycarbonate sheets, inc new Purlins, Gutters & Flashings",
    imageUrls: [
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/wainscott/5.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/wainscott/4.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/wainscott/3.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/wainscott/2.jpg",
      "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/wainscott/1.jpg",
    ],
  },
];

export default function RoofingCladdingPage(): ReactElement {
  const classes = useStyles();

  return (
    <>
      <TopBanner backgroundImage="https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/caldecote-farm/WhatsApp+Image+2024-10-15+at+19.28.19_0634e029.jpg" />
      <Container className={classes.container} maxWidth="lg">
        {/* <Typography className={classes.title} variant="h4">
          Commercial buildings for every kind of client, every size of budget.
        </Typography> */}
        <Typography className={classes.subTitle} variant="subtitle1">
          As one of Northamptonshire&apos;s leading roofing & cladding suppliers
          we undertake a wide variety of roofing & cladding projects. We
          coordinate the design with the architect, engineer, steelwork,
          brickwork and window and door contractors to ensure all details work
          correctly. With a vast experience of all types of roofing we have
          become specialist in the design and installation of a diverse range of
          industrial products. Composite panels, built up systems, standing
          seams and single ply.
        </Typography>
        <MultiGalleryCarousel buildingImages={buildingImages} />
      </Container>
    </>
  );
}
