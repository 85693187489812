import { Container, makeStyles, Modal } from "@material-ui/core";
import { ReactElement, useState } from "react";
import { Carousel } from "react-responsive-carousel";
interface Props {
  title: string;
  images: Array<string>;
}

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    padding: "3rem 0",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  imageContainerLarger: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  image: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    cursor: "pointer",
    width: 400,
    height: 300,
    margin: "0.5rem 0",
    [theme.breakpoints.down("xs")]: {
      width: 300,
      height: 250,
    },
  },
  imageLarger: {
    width: 600,
    height: 400,
    [theme.breakpoints.down("xs")]: {
      width: 300,
      height: 250,
    },
  },
  carouselRoot: {
    display: "flex",
  },
  carouselContainer: {
    margin: "auto",
    width: "80vw",
  },
}));

export default function GalleryCarousel({
  title,
  images,
}: Props): ReactElement {
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const classes = useStyles();
  const selectedItem = images.findIndex(
    (imageUrl) => imageUrl === selectedImageUrl
  );

  return (
    <>
      <Container
        className={
          images.length === 2
            ? `${classes.imageContainer} ${classes.imageContainerLarger}`
            : classes.imageContainer
        }
        maxWidth="lg"
      >
        {images.map((image, index) => (
          <div
            style={{ backgroundImage: `url("${image}")` }}
            className={
              images.length === 2
                ? `${classes.image} ${classes.imageLarger}`
                : classes.image
            }
            key={`image-${index}`}
            onClick={() => setSelectedImageUrl(image)}
          ></div>
        ))}
        {images.length > 3 && images.length % 3 === 2 && (
          <div className={classes.image}></div>
        )}
      </Container>
      <Modal
        open={Boolean(selectedImageUrl)}
        onClose={() => setSelectedImageUrl("")}
        className={classes.carouselRoot}
      >
        <div className={classes.carouselContainer}>
          <Carousel
            useKeyboardArrows
            infiniteLoop
            autoFocus
            showThumbs={false}
            showStatus={false}
            selectedItem={selectedItem}
            dynamicHeight={false}
          >
            {images.map((imageUrl, index) => (
              <div key={`carousel-img-${index}`}>
                <img src={imageUrl} alt={title} />
              </div>
            ))}
          </Carousel>
        </div>
      </Modal>
    </>
  );
}
