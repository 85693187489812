import { ReactElement } from "react";
import { MuiThemeProvider as ThemeProvider } from "@material-ui/core";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "./Header";
import Footer from "./Footer";
import theme from "../theme";
import ScrollToTop from "./ScrollToTop";
// Pages
import HomePage from "../pages/home";
import AboutPage from "../pages/about";
import CommericalBuildingPage from "../pages/buildings/commercial";
import AgriculturalBuildingPage from "../pages/buildings/agricultural";
import GroundworkBuildingPage from "../pages/buildings/groundwork";
import ContactPage from "../pages/contact";
import RoofingCladdingPage from "../pages/buildings/roofing-cladding";

function App(): ReactElement {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>FGA Group Ltd</title>
        <meta
          name="description"
          content="Steel Frame Buildings, Roofing & Wall Cladding Main Contractor"
        />
      </Helmet>
      <Router>
        <ScrollToTop />
        <Header />
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/about">
            <AboutPage />
          </Route>
          <Route path="/commericalBuildings">
            <CommericalBuildingPage />
          </Route>
          <Route path="/agriculturalBuildings">
            <AgriculturalBuildingPage />
          </Route>
          <Route path="/groundwork">
            <GroundworkBuildingPage />
          </Route>
          <Route path="/roofing-cladding">
            <RoofingCladdingPage />
          </Route>
          <Route path="/contact">
            <ContactPage />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
