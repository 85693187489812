import { ReactElement } from "react";
import { Container, makeStyles, Typography } from "@material-ui/core";
import GalleryCarousel from "../../components/GalleryCarousel";
import TopTitle from "../../components/TopTitle";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "5rem 0",
    [theme.breakpoints.down("md")]: {
      padding: "1rem 2rem",
    },
  },
  title: {
    margin: "2rem 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  subTitle: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.9rem",
    },
  },
}));

const galleryImages = [
  "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/new-ground-1.jpg",
  "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/new-ground-2.jpg",
  "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/new-ground-3.jpg",
  "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/new-ground-4.jpg",
  "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/new-ground-5.jpg",
  "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/new-ground-6.jpg",
  "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/new-ground-7.jpg",
  "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/new-ground-8.jpg",
  "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/groudwork/1665630934687.jpg",
  "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/groudwork/Image+2.jpeg",
  "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/groudwork/9712bdfd-0b9d-48f0-b440-a367759e44fd.JPG",
  "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/buildings/groudwork/IMG_6791.jpg",
  "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/groundwork1.jpeg",
  "https://upgetgomedia.s3.eu-west-2.amazonaws.com/fgaGroup/groundwork2.jpeg",
];

export default function GroundworkBuildingPage(): ReactElement {
  const classes = useStyles();

  return (
    <>
      <TopTitle title="Groundworks" />
      <Container className={classes.container} maxWidth="lg">
        <Typography className={classes.title} variant="h4">
          Construction services backed by skilled craftsmanship and proven
          experience.
        </Typography>
        <Typography className={classes.subTitle} variant="subtitle1">
          We offer an extensive range of groundwork services from, foundations,
          preparing sub bases, ducting, drainage works, laying of insulating
          floors, power float finish concrete floors, reinforced slabs &
          concrete aprons
        </Typography>
        <GalleryCarousel images={galleryImages} title="Groundworks" />
      </Container>
    </>
  );
}
