const navLinks: {
  name: string;
  link: string;
  subMenuItems?: {
    name: string;
    link: string;
  }[];
}[] = [
  {
    name: "About",
    link: "/about",
  },
  {
    name: "Agricultural",
    link: "/agriculturalBuildings",
  },
  {
    name: "Commercial",
    link: "/commericalBuildings",
  },
  {
    name: "Roofing & Cladding",
    link: "/roofing-cladding",
  },
  {
    name: "Groundworks",
    link: "/groundwork",
  },
];

const contacts = [
  {
    name: "Julian Arrowsmith",
    text: "+44 (0) 7527 395424",
    tel: "tel:+447527395424",
    emailText: "julian@fgagroup.co.uk",
    email: "mailto:julian@fgagroup.co.uk",
  },
  {
    name: "Harry Groome",
    text: "+44 (0) 7805 419544",
    tel: "tel:+447805419544",
    emailText: "harry@fgagroup.co.uk",
    email: "mailto:harry@fgagroup.co.uk",
  },
];

export { navLinks, contacts };
