import { ReactElement } from "react";
import ShowcaseBanner from "../../components/ShowcaseBanner";
import HomePhoto from "../../components/HomePhoto";
import ClientLogos from "../../components/ClientLogos";

export default function HomePage(): ReactElement {
  return (
    <>
      <HomePhoto />
      <ShowcaseBanner />
      <ClientLogos />
    </>
  );
}
